import { Container } from 'theme-ui';

import { usePickedSections } from '@hooks';
import { Breadcrumbs } from '@snippets';

export function HomeTemplate({ renderSections }) {
  const sections = renderSections();
  const { firstOf, otherSections } = usePickedSections({
    firstOf: ['quick-select-collections'],
    sections,
  });

  return (
    <Container data-comp="HomeTemplate">
      <Breadcrumbs isHome />
      {firstOf['quick-select-collections'] || null}

      {otherSections}
    </Container>
  );
}
